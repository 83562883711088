import './App.scss';

import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { AppLoading } from './components/AppLoading';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import ProductDetail from './features/Cashback/ProductDetail';
import ShopDetail from './features/Cashback/ShopDetail';
import { Error404 } from './features/Error404';
import { Home } from './features/Home/Home';
import InfoPage from './features/InfoPage/InfoPage';
import Registration from './features/Registration/Registration';
import SetNewPassword from './features/SetNewPassword';
import UnsubscribeFromNewsletter from './features/UnsubscribeFromNewsletter';
import VerifyAccount from './features/VerifyAccount';
import { User } from './interfaces/user';
import {
  footerItems,
  hiddenRoutes,
  navigationItems,
  Route as RouteInterface,
  subNavigationItems,
} from './routes';
import { Alert } from './services/alert';
import Auth from './services/auth';
import Helper from './services/helper';
import UserManager from './services/manager/UserManager';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ContentElements } from './interfaces/ContentElements';
import CmsManager from './services/manager/CmsManager';
import Cookies from 'universal-cookie';

interface Props {
  history?: any;
}

interface State {
  loggedIn: boolean;
  loading: boolean;
  user?: User;
  content?: ContentElements;
}

const cookies = new Cookies();

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loggedIn: false,
      loading: true,
    };
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  getJsonFromUrl = () => {
    const query = window.location.search.substr(1);
    const result: any = {};

    query.split('&').forEach(part => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
  };

  async componentDidMount() {
    let user = undefined;
    const queryParams = this.getJsonFromUrl();

    if (queryParams.token && queryParams['iam-auth'] === '1') {
      await Auth.passAuthTokenFromMlpToIam(queryParams.token);
    }

    const loggedIn = await Auth.checkLogin();

    if (loggedIn) {
      user = await UserManager.me();
    }

    this.setState({
      loggedIn,
      user,
      loading: false,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.loggedIn) {
      const hasShownPopup = sessionStorage.getItem('hasShownPopup');
      if (!hasShownPopup) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: 'Wichtige Information zum MLP-Cashback-Service',
          html: `Wir möchten Sie darüber informieren, dass der MLP-Cashback-Service 
          ab dem 18.03.2025 nicht mehr verfügbar sein wird. Ihr gesammeltes Cashback 
          wird Ihnen jedoch selbstverständlich final zum 18.09.2025 ausgezahlt.
          <br /><br />
          <b>Bitte stellen Sie hierfür sicher,</b> dass Ihre IBAN im Bereich „<a href="/mein-konto">Mein Konto</a>“ 
          hinterlegt ist und Sie den Link in der Opt-In-Mail bestätigt haben.
          <br /><br />
          <b>Um eine reibungslose Auszahlung zu gewährleisten, bitten wir Sie, Ihre IBAN zu überprüfen.</b>
          <br /><br />
          Vielen Dank, dass Sie unseren Service genutzt haben!`,
          icon: 'warning',
          customClass: {
            popup: 'swal2-wide-popup',
          },
          showClass: {
            popup: 'swal2-noanimation',
          },
          hideClass: {
            popup: '',
          },
          confirmButtonText: 'Jetzt IBAN prüfen',
          showCloseButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href =
              '/meine-daten/meine-auszahlungseinstellungen';
          }
        });
        sessionStorage.setItem('hasShownPopup', 'true');
      }
    }
  }

  render() {
    if (this.state.loading) return <AppLoading />;

    return (
      <div>
        <BrowserRouter basename="/">
          <ScrollToTop />

          <Switch>
            <Route exact path="/" component={Home} />

            {[
              ...navigationItems,
              ...footerItems,
              ...hiddenRoutes,
              ...subNavigationItems,
            ].map((item: RouteInterface, index) => {
              if (item.component) {
                const attr = {
                  exact: true,
                  key: 'route-' + index,
                  path: item.link,
                  component: item.component,
                };

                if (
                  this.state.user &&
                  !Helper.canViewRoute(
                    this.state.loggedIn,
                    item.link,
                    this.state.user?.userInfo._aditoBasicContractType
                  )
                ) {
                  return (
                    <Route exact path={item.link}>
                      <Redirect to="/" />
                    </Route>
                  );
                } else if (item.private) {
                  return <PrivateRoute {...attr} />;
                } else {
                  return <Route {...attr} />;
                }
              }

              return false;
            })}

            <Route exact path="/produkt/:id" component={ProductDetail} />
            <Route exact path="/shop/:id" component={ShopDetail} />
            <Route exact path="/info/:id" component={InfoPage} />
            <Route exact path="/registrierung/:type" component={Registration} />
            <Route exact path="/cashback">
              <Redirect to="/cashback/shops" />
            </Route>
            <Route
              exact
              path="/passwort-zuruecksetzen"
              component={SetNewPassword}
            />
            <Route exact path="/konto-aktivieren" component={VerifyAccount} />
            <Route
              exact
              path="/newsletter/abmeldung"
              component={UnsubscribeFromNewsletter}
            />

            {/* redirects */}
            <Route exact path="/cashback-radar">
              <Redirect to="/cashbackradar" />
            </Route>

            <Route exact path="/karten-und-dokumentenschutz">
              <Redirect to="/sicherheit#kartenunddokumentenschutz" />
            </Route>

            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
